import {
  APOLLO_NAMED_OPTIONS,
  ApolloModule,
  NamedOptions,
} from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { NgModule } from "@angular/core";
import { ApolloLink, InMemoryCache, split } from "@apollo/client/core";
import { getMainDefinition } from "@apollo/client/utilities";
import { HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

const apiUrl = environment.apiUrl;

const userAccessUri = apiUrl + "/user-access/graphql"; // <-- GraphQL server URL
const adminApiUri = apiUrl + "/admin/graphql"; // <-- GraphQL server URL

export function createApolloUserAccess(httpLink: HttpLink): NamedOptions {
  return {
    userAccess: {
      link: httpLink.create({ uri: userAccessUri }),
      cache: new InMemoryCache(),
    },
  };
}
export function createApolloAdminApi(httpLink: HttpLink): NamedOptions {
  return {
    adminApi: {
      link: httpLink.create({ uri: adminApiUri }),
      cache: new InMemoryCache(),
    },
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: (httpLink: HttpLink) => ({
        ...createApolloUserAccess(httpLink),
        ...createApolloAdminApi(httpLink),
      }),
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
