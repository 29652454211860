import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/auth.service";
import { User } from "src/app/user";
import { UserService } from "src/app/user.service";

var misc: any = {
  sidebar_mini_active: true,
};

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  permission?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
  permission?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    type: "link",
    icontype: "design_app",
    permission: "Dashboard",
  },
  {
    path: "/vehicle",
    title: "Vehicle",
    type: "sub",
    icontype: "business_bank",
    collapse: "vehicle",
    isCollapsed: true,
    children: [
      { path: "vehicles", title: "Vehicles", ab: "V", permission: "Vehicles" },
      { path: "vehicle-models", title: "Model Years", ab: "MY", permission: "Vehicles" },
      { path: "vehicle-trims", title: "Vehicle Trims", ab: "VT", permission: "Vehicle-trims" },
      { path: "service-menu-prices", title: "Service Menu Prices", ab: "SMP", permission: "Vehicles" },
      { path: "media-gallery", title: "Media Gallery", ab: "MG", permission: "Vehicles" }
    ],
  },
  {
    path: "/data-bank",
    title: "Data Bank",
    type: "sub",
    icontype: "business_bank",
    collapse: "data_bank",
    isCollapsed: true,
    children: [
      { path: "brands", title: "Brands", ab: "B", permission: "Branches" },
      { path: "branches", title: "Branches", ab: "BH", permission: "Branches" },
      { path: "category-specifications", title: "Category Specifications", ab: "CS", permission: "Category-specifications" },
      { path: "specifications", title: "Specifications", ab: "S", permission: "Specifications" },
      { path: "service-categories", title: "Service Categories", ab: "SC", permission: "Service-categories" },
      { path: "service-items", title: "Service Items", ab: "SI", permission: "Service-items" },
      { path: "engines", title: "Engines", ab: "E", permission: "Vehicles" },
      { path: "fuel-types", title: "Fuel Types", ab: "FT", permission: "Vehicles" },
      { path: "regions", title: "Regions", ab: "R", permission: "Vehicles" },
      { path: "cities", title: "Cities", ab: "C", permission: "Vehicles" },
    ],
  },
  {
    path: "/complaint",
    title: "Complaint",
    type: "sub",
    icontype: "business_bank",
    collapse: "complaint",
    isCollapsed: true,
    children: [
      { path: "complains", title: "Complains", ab: "C", permission: "Branches" },
      { path: "complain-categories", title: "Complain Categories", ab: "CC", permission: "Branches" },
      { path: "complain-sub-categories", title: "Complain Subcategories", ab: "CSC", permission: "Branches" },
      { path: "complain-types", title: "Complain Types", ab: "CSC", permission: "Branches" },
    ],
  },
  {
    path: "/notification",
    title: "Notification",
    type: "sub",
    icontype: "design_app",
    collapse: "notification",
    isCollapsed: true,
    children: [
      { path: "customers", title: "Customers", ab: "C", permission: "Branches" },
      { path: "sms-templates", title: "SMS Templates", ab: "ST", permission: "Branches" },
      { path: "send-sms", title: "Send SMS Notification", ab: "SSN", permission: "Branches" },
      { path: "sms-queue", title: "SMS Queue", ab: "SQ", permission: "Branches" },
      { path: "queue-requests", title: "Queue Requests", ab: "QR", permission: "Branches" },
      // { path: "complain-categories", title: "Complain Categories", ab: "CC", permission: "Branches" },
      // { path: "complain-sub-categories", title: "Complain Subcategories", ab: "CSC", permission: "Branches" },
      // { path: "complain-types", title: "Complain Types", ab: "CSC", permission: "Branches" },
    ],
  },
  {
    path: "/marketing",
    title: "Marketing",
    type: "sub",
    icontype: "design_vector",
    collapse: "marketing",
    isCollapsed: true,
    children: [
      {
        path: "campaigns",
        title: "Campaigns",
        ab: "C",
        permission: "Campaigns",
      },
      {
        path: "third-party-accounts",
        title: "Third Party Accounts",
        ab: "3rd",
        permission: "Third-party-accounts",
      },
      {
        path: "campaign-account-channel",
        title: "Assign campaign",
        ab: "AC",
        permission: "Assign-campaign",
      },
      {
        path: "channel",
        title: "Channels",
        ab: "C",
        permission: "Channels",
      },
    ],
  },
  {
    path: "/sales",
    title: "Sales",
    type: "sub",
    icontype: "business_money-coins",
    collapse: "sales",
    isCollapsed: true,
    children: [{ path: "leads", title: "leads", ab: "L", permission: "Leads" }],
  },
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  public isCollapsed = false;
  user!: User;
  userData$;
  constructor(
    private toastr: ToastrService,
    private userService: UserService,
    private authService: AuthService
  ) { }
  ngOnInit() {
    this.userData$ = this.userService.userData$;
    this.userData$.subscribe((userData) => {
      this.user = userData;
    });
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
  }
  sleep(milliseconds) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if (new Date().getTime() - start > milliseconds) {
        break;
      }
    }
  }
  myFunc(event, menuitem) {
    event.preventDefault();
    event.stopPropagation();
    this.sleep(10);
    if (menuitem.isCollapsing === undefined) {
      menuitem.isCollapsing = true;

      // menuitem.isCollapsed = !menuitem.isCollapsed;

      var element = event.target;
      while (
        element.getAttribute("data-toggle") != "collapse" &&
        element != document.getElementsByTagName("html")[0]
      ) {
        element = element.parentNode;
      }
      element = element.parentNode.children[1];

      if (
        element.classList.contains("collapse") &&
        !element.classList.contains("show")
      ) {
        element.classList = "before-collapsing";
        var style = element.scrollHeight;

        element.classList = "collapsing";
        setTimeout(function () {
          element.setAttribute("style", "height:" + style + "px");
        }, 1);
        setTimeout(function () {
          element.classList = "collapse show";
          element.removeAttribute("style");
          menuitem.isCollapsing = undefined;
        }, 350);
      } else {
        var style = element.scrollHeight;
        setTimeout(function () {
          element.setAttribute("style", "height:" + (style + 20) + "px");
        }, 3);
        setTimeout(function () {
          element.classList = "collapsing";
        }, 3);
        setTimeout(function () {
          element.removeAttribute("style");
        }, 20);
        setTimeout(function () {
          element.classList = "collapse";
          menuitem.isCollapsing = undefined;
        }, 400);
      }
    }
  }
  minimizeSidebar() {
    const body = document.getElementsByTagName("body")[0];
    if (body.classList.contains("sidebar-mini")) {
      misc.sidebar_mini_active = true;
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
      body.classList.remove("sidebar-mini");
      misc.sidebar_mini_active = false;
      this.showSidebarMessage("Sidebar mini deactivated...");
    } else {
      body.classList.add("sidebar-mini");
      this.showSidebarMessage("Sidebar mini activated...");
      misc.sidebar_mini_active = true;
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event("resize"));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  showSidebarMessage(message) {
    this.toastr.show(
      '<span class="now-ui-icons ui-1_bell-53"></span>',
      message,
      {
        timeOut: 4000,
        closeButton: true,
        enableHtml: true,
        toastClass: "alert alert-danger alert-with-icon",
        positionClass: "toast-top-right",
      }
    );
  }
  logout() {
    this.userService.clearUserData();
    this.authService.clearTokens();
  }
  hasChildPermission(menuitem: any): boolean {
    return menuitem.children.some((child: any) =>
      this.user?.permissions?.[child.permission]?.includes("VIEW")
    );
  }
}
