import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userDataSubject = new BehaviorSubject<any>(null);
  userData$ = this.userDataSubject.asObservable();

  constructor() {}

  setUserData(userData: any): void {
    this.userDataSubject.next(userData);
  }

  clearUserData(): void {
    this.userDataSubject.next(null);
  }
}
