import { Component, OnInit } from "@angular/core";

import { Router } from "@angular/router";
import { AuthService } from "src/app/auth.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-auth-layout",
  templateUrl: "./auth-layout.component.html",
  styleUrls: ["./auth-layout.component.css"],
})
export class AuthLayoutComponent implements OnInit {
  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit() {
    const hostname = window.location.hostname;
    const url = `https://${hostname}`;
    const authUrl = environment.authUrl;
    /*if (authUrl != url) {
      window.location.href =
        environment.authUrl +
        "/#/login?app_code=admin-panel&redirect_url=" +
        environment.panelUrl;
    }*/
  }
}
