export class User {
  uid!: string;
  name!: string;
  email!: string;
  jobTittle!: string;
  roleName!: string;
  roleUid!: string;
  permissions!: any;
  branches!: string[];
  constructor(accessToken: any) {
    const accessTokenData = JSON.parse(atob(accessToken.split('.')[1]));
    this.uid = accessTokenData.user.uid;
    this.name = accessTokenData.user.full_name;
    this.email = accessTokenData.user.email;
    this.jobTittle = accessTokenData.user.job_tittle;
    this.branches = accessTokenData.branches;
    this.roleName = accessTokenData.role.name;
    this.roleUid = accessTokenData.role.uid;
    this.permissions = accessTokenData.role.access_controls;
  }
}
